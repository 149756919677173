<template>
  <div ref="dfmembership" class="df-member-ship">
    <!-- top -->
    <el-row class="df-member-row">
      <el-col :span="24"> </el-col>
    </el-row>

    <div class="df-row">
      <div class="df-col">
        <!-- 选择门店 -->
        <el-select v-if="authType == 'H'" v-model="submitData.StoresGuid" size="mini" placeholder="请选择门店">
          <el-option v-for="item in storesData" :key="item.StoresGuid" :label="item.StoresName" :value="item.StoresGuid">
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 会员标签下拉框 -->
        <el-select v-model="submitData.MemberTag" size="mini" placeholder="请选择会员标签">
          <el-option v-for="item in advancedQuery.memberTag" :key="item.TagGuid" :label="item.TagName"
            :value="item.TagGuid">
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 会员分类下拉框 -->
        <el-select v-model="submitData.MemberKind" placeholder="请选择分类" size="mini">
          <el-option v-for="item in advancedQuery.kindList" :key="item.KindGuid" :label="item.KindName"
            :value="item.KindGuid">
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 搜索框 -->
        <el-input placeholder="请输入姓名 / 手机号码" v-model="submitData.Word" size="mini">
        </el-input>
      </div>
      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button @click="onSearch" size="mini" type="primary">
          查询
        </el-button>
      </div>
      <div class="df-col">
        <!-- 清空按钮 -->
        <el-button @click="onEmptySearch" size="mini" type="primary">
          清空查询
        </el-button>
      </div>
      <div class="df-col">
        <!-- 高级查询 -->
        <el-button @click="onSeniorDialog" size="mini" type="primary">
          高级搜索
        </el-button>
      </div>
      <div class="df-col">
        <!-- 自定义表格列弹出框 -->
        <el-popover placement="bottom-end" width="300" trigger="click" v-model="visible">
          <div>
            <div class="header">
              <span>自定义列</span>
            </div>

            <!-- 表格每列表头 -->
            <div class="list" v-for="(item, index) in heads" :key="index">
              <el-checkbox v-model="item.Visible" :label="item.Visible" :true-label="1" :false-label="0">
                <span>{{ item.Name }}</span>
              </el-checkbox>
            </div>

            <!-- popover腿部 -->
            <div class="footer">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                全选
              </el-checkbox>
              <div>
                <el-button @click="onCancelTable" size="mini">取消</el-button>
                <el-button @click="onConfirmTable" type="primary" size="mini">确定</el-button>
              </div>
            </div>
          </div>

          <!-- 自定义表格按钮 -->
          <el-button slot="reference" size="mini" type="primary">自定义表格</el-button>
        </el-popover>
      </div>
      <div class="df-col">
        <!-- 新增会员按钮 -->
        <el-button @click="onAddMember" size="mini" type="primary" icon="el-icon-plus">
          新增会员
        </el-button>
      </div>
      <div class="df-col">
        <!-- 设置会员分类按钮 -->
        <el-button @click="onKindDialog" size="mini" type="primary">
          批量设置会员分类
        </el-button>
      </div>
    </div>

    <!-- 当前门店会员信息统计 -->
    <el-row v-if="accountInfo.authRoleNo == '0001'">
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ statisticalAmount.TotalBalance }}</span>
          <span>储值余额</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ statisticalAmount.ExpireBalance }}</span>
          <span>过期卡金</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ statisticalAmount.TotalDonaBalance }}</span>
          <span>赠金总额</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ statisticalAmount.ExpireDonaBalance }}</span>
          <span>过期赠金</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span>￥{{ statisticalAmount.DebtBalance }}</span>
          <span>欠款</span>
        </div>
      </el-col>
    </el-row>

    <!-- start 会员信息表 -->
    <el-table :data="dataList" style="width: 100%" :max-height="tableMaxHeight" @selection-change="onChangeSelection"
      v-loading="loading">
      <el-table-column type="selection" width="48"> </el-table-column>
      <el-table-column label="姓名" width="250" fixed="left">
        <template slot-scope="scope">
          <div class="member-name">
            <div class="member-name-rigth">
              <el-image :src="scope.row.MemberHead">
                <div slot="error" style="
                    height: 100%;
                    width: 100%;
                    background-color: #c8c5c8;
                    border-radius: 5px;
                  ">
                  <div style="
                      height: 40px;
                      line-height: 40px;
                      text-align: center;
                      font-weight: bold;
                      font-size: 18px;
                    ">
                    {{ scope.row.MemberName.substring(0, 1) }}
                  </div>
                  <el-tag type="warning" size="mini" style="width: 100%; text-align: center">{{ scope.row.LossType
                  }}</el-tag>
                </div>
              </el-image>
            </div>
            <div>
              <div>
                <span>{{ scope.row.MemberName }}</span>
                <i :class="scope.row.MemberSex == 0 ? 'el-icon-female' : 'el-icon-male'
                  " style="margin-left: 5px; font-size: 15px; font-weight: bold"></i>
              </div>
              <div class="size">{{ scope.row.MemberPhone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 自定义列，由于表格需要自定义，只能依靠循环渲染列的数据，而列的循环顺序则依赖后端接口返回的data顺序，
      所以如果需要修改渲染出来的顺序就要让后端改 -->
      <el-table-column v-for="(item, index) in headList" :key="index"
        :show-overflow-tooltip="item.DataKey == 'MemberNote' ? true : false" :label="item.Name" :prop="item.DataKey"
        width="180">
        <template slot-scope="scope">
          <div>
            <!-- 由于表格的特殊需求，下面根据特殊内容的列，例如：要插入tag、插入popper等需要做v-if，其他列正常则直接输出-->
            <!-- 这些v-if的都是特殊输出的列 -->
            <div v-if="item.DataKey == 'Birthday'">
              {{
                scope.row[item.DataKey]
                ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row[item.DataKey] * 1000)
                )
                : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'MemberTags'">
              <el-tag v-for="(tagItem, tagIndex) in scope.row[item.DataKey]" :key="'tag' + tagIndex" size="small">
                {{ tagItem }}
              </el-tag>
            </div>
            <div v-else-if="item.DataKey == 'DimeSigners'">
              <div class="dime-sign">
                <div class="sign-text" v-for="(dimeItem, dimeIndex) in scope.row[item.DataKey]" :key="dimeIndex"
                  :style="'background:' + dimeItem.SignColor">
                  {{ dimeItem.SignText }}
                </div>
              </div>
            </div>
            <div v-else-if="item.DataKey == 'VipInfos'">
              <el-popover placement="bottom" width="400" trigger="click">
                <div class="member-card">
                  <div class="member-card-list" v-for="(vipItem, vipIndex) in scope.row[item.DataKey]"
                    :key="'vip' + vipIndex">
                    <div class="title">
                      <span>{{ vipItem.CardName }}</span>
                      <span>（{{ vipItem.VipNo }}）</span>
                    </div>
                    <ul class="grid">
                      <li>
                        <p>充值余额</p>
                        <p>{{ vipItem.KeepBalance }}</p>
                      </li>
                      <li>
                        <p>赠送金额</p>
                        <p>{{ vipItem.DonaBalance }}</p>
                      </li>
                      <li>
                        <p>卡的积分</p>
                        <p>{{ vipItem.InteBalance }}</p>
                      </li>
                      <li>
                        <p>卡的欠款</p>
                        <p>{{ vipItem.DebtBalance }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <span v-if="scope.row[item.DataKey][0]" slot="reference" class="look-card">
                  <span>{{ scope.row[item.DataKey][0].CardName }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </span>
              </el-popover>
            </div>
            <div v-else-if="item.DataKey == 'LastExpeTime'">
              {{
                scope.row[item.DataKey]
                ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row[item.DataKey] * 1000)
                )
                : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'RegisterDate'">
              {{
                scope.row[item.DataKey]
                ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row[item.DataKey] * 1000)
                )
                : ""
              }}
            </div>
            <!-- 这里是正常输出的列 -->
            <div v-else>
              {{ scope.row[item.DataKey] }}
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 功能按钮区域 -->
      <el-table-column label="操作" width="230" fixed="right">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" size="mini" @click="onAlterMember(scope.row)">
              修改
            </el-button>
            <el-button type="success" size="mini" :disabled="disableDisabled" @click="onblockUpMember(scope.row)">
              停用
            </el-button>
            <el-button type="danger" size="mini" :disabled="deleteDisabled" @click="onDeleteMember(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 会员信息表 -->

    <!-- start 分页 -->
    <div class="pagination">
      <el-pagination background :page-sizes="[10, 20, 30, 40, 50]" :page-size="submitData.PageSize"
        :current-page="submitData.PageIndex" :total="submitData.TotalRecord"
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- end 分页 -->

    <!-- 修改会员对话框 -->
    <AlterDialog ref="alterDialog" @success="initPageData"></AlterDialog>

    <!-- 新增会员对话框 -->
    <AddDialog ref="addMember"></AddDialog>

    <!-- 高级搜索对话框 -->
    <SeniorDialog ref="seniorDialog" @confirmSenior="onConfirmSenior"></SeniorDialog>

    <!-- 设置会员分类对话框 -->
    <KindDialog ref="kindDialog" @success="initPageData"></KindDialog>
  </div>
</template>

<script>
import api from "@/api/member.js";
import AddDialog from "./components/add-dialog.vue";
import AlterDialog from "./components/alter-dialog.vue";
import SeniorDialog from "./components/senior-dialog.vue";
import KindDialog from "./components/kind-dialog.vue";
export default {
  components: { AddDialog, AlterDialog, SeniorDialog, KindDialog },
  data() {
    return {
      statisticalAmount: {}, // 会员余额信息
      heads: [], // 自定义表格多选框数据
      submitData: {
        // 提交数据
        VipNo: "",
        MemberName: "",
        MemberPhone: "",
        MemberKind: "",
        MemberState: 1,
        MemberSex: -1,
        StoresGuid: "",
        StoresShare: -1,
        MemberFollow: "",
        SourceWay: -1,
        LossLevel: 0,
        MemberTag: "",
        IntroduceGuid: "",
        StarBirthday: "",
        EndBirthday: "",
        StartAge: 0,
        EndAge: 0,
        StartExpeFreq: 0,
        EndExpeFreq: 0,
        StartLastExpeAway: 0,
        EndLastExpeAway: 0,
        StartLastExpeDate: "",
        EndLastExpeDate: "",
        PageSize: 10,
        PageIndex: 1,
        TotalRecord: 0,
        Word: "",
      },
      authType: "", // 账号权限
      storesData: [], // 门店列表
      dataList: [], // 会员信息列表
      headList: [], // 自定义列表头
      selectedList: [], // 表格选中的会员列表
      tableMaxHeight: "", // 表格最大高度
      accountInfo: {},
      advancedQuery: {}, // 页面的所有初始化数据
      isIndeterminate: true, // 控制是否全选
      visible: false, // 弹出框显示
      checkAll: false, // 全选框当前状态
      loading: false, // 表格加载
      deleteDisabled: false, // 删除按钮禁用
      disableDisabled: false, // 停用按钮禁用
    };
  },

  created() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.accountInfo = accountInfo
    this.getResize(); // 要在created()调用，el-table的max-height会在dom实例挂载前报错
  },

  mounted() {
    // 添加窗口高度变化的监听事件
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;

    if (this.authType == "H") {
      this.storesData = [
        { StoresGuid: "", StoresName: "全部门店" },
        ...accountInfo.manageStoresList,
      ];
    }

    this.initMemberMaintQuery();
    this.queryAmount();
    this.initPageData();
    this.queryCustomTable();
  },

  destroyed() {
    // 清除监听事件，切记window.addEventListener这样写的监听事件必须清除
    // 不然这个页面每次mounted生命周期都会增加一个监听，导致不断占用资源
    // 不用vue的watch监听事件是因为，前任前端只写了添加监听没写销毁，我就补一个销毁事件也懒得改啦:b
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 48 + 84 + (this.accountInfo.authRoleNo == '0001' ? 76 : 0));
    },

    // 初始化下拉框用到的数据
    async initMemberMaintQuery() {
      try {
        let { data } = await api.initMemberMaintQuery();
        this.advancedQuery = data;
      } catch (e) {
        console.log(e);
      }
    },

    // 初始化自定义列表头
    async queryCustomTable() {
      try {
        let { data } = await api.memberMaintSettingFetchCustom({
          subact: "fetch_custom",
        });
        let { Heads, HeadTops } = data;
        this.heads = Heads;
        let bool = this.heads.every((item) => {
          return item.Visible == 1;
        });
        this.checkAll = bool;
      } catch (e) { }
    },

    // 获取会员余额信息
    async queryAmount() {
      try {
        let { data } = await api.getTotalBalance();
        this.statisticalAmount = data;
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 初始化会员信息表格数据
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.getMemberMaintList(this.submitData);
        let { filter, result } = data;
        let { DataList, HeadList } = result;
        this.dataList = DataList;
        this.headList = HeadList;
        this.submitData = filter;
      } catch (e) { }
      this.loading = false;
    },

    // 表格会员被选中事件
    onChangeSelection(event) {
      this.selectedList = event;
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    // 清空查询按钮点击事件
    onEmptySearch() {
      this.submitData = {
        VipNo: "",
        MemberName: "",
        MemberPhone: "",
        MemberKind: "",
        MemberState: 1,
        MemberSex: -1,
        StoresShare: -1,
        MemberFollow: "",
        SourceWay: -1,
        LossLevel: 0,
        MemberTag: "",
        IntroduceGuid: "",
        StarBirthday: "",
        EndBirthday: "",
        StartAge: 0,
        EndAge: 0,
        StartExpeFreq: 0,
        EndExpeFreq: 0,
        StartLastExpeAway: 0,
        EndLastExpeAway: 0,
        StartLastExpeDate: "",
        EndLastExpeDate: "",
        PageSize: 10,
        PageIndex: 1,
        TotalRecord: 0,
        Word: "",
      };
      this.initPageData();
    },

    // 打开添加会员对话框
    onAddMember() {
      this.$refs.addMember.openAddMemberDialog();
    },

    // 打开高级查询对话框
    onSeniorDialog() {
      this.$refs.seniorDialog.openSeniorDialog(
        this.submitData,
        this.advancedQuery
      );
    },

    // 打开设置会员分类对话框
    onKindDialog() {
      let arr = this.util.jsonCopy(this.advancedQuery.kindList);
      arr.filter((item, index) =>
        item.KindGuid == "" ? (arr[index].KindName = "请选择") : ""
      );
      this.$refs.kindDialog.onShowDialog(arr, this.selectedList);
    },

    // 打开修改会员对话框
    onAlterMember(event) {
      this.$refs.alterDialog.openAlterDialog(event);
    },

    // 停用会员点击事件
    onblockUpMember(event) {
      this.disableDisabled = true;
      this.$confirm(`是否停用该‘${event.MemberName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.disableMember({
              member_guid: event.MemberGuid,
            });
            this.$message({
              message: "停用成功!",
              type: "success",
            });
            this.initPageData();
          } catch (e) { }
        })
        .finally(() => {
          this.disableDisabled = false;
        });
    },

    // 删除会员点击事件
    onDeleteMember(event) {
      this.deleteDisabled = true;
      this.$confirm(`是否删除该‘${event.MemberName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.deleteMember({
              member_guid: event.MemberGuid,
            });
            this.$message({
              message: "删除成功!",
              type: "success",
            });
            this.initPageData();
          } catch (err) { }
        })
        .finally(() => {
          this.deleteDisabled = false;
        });
    },

    // 全选框变化事件
    handleCheckAllChange(val) {
      this.heads.forEach((item) => {
        item.Visible = val ? 1 : 0;
      });
      this.isIndeterminate = false;
    },

    // 自定义弹出框取消事件
    onCancelTable() {
      this.visible = false;
    },

    // 自定义弹出框确定事件
    async onConfirmTable() {
      try {
        // 保存自定义列多选的表头数据到数据库，让每次刷新获取表格数据的接口返回的是你保存的自定义列对应的表格数据
        let { data } = await api.memberMaintSettingSaveCustom({
          Heads: this.heads,
        });
        this.visible = false;
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 页数变化事件
    handleSizeChange(event) {
      this.submitData.PageSize = event;
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    // 页码变化事件
    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.initPageData();
    },

    // 高级查询确定事件
    onConfirmSenior(event) {
      this.submitData = event;
      this.submitData.PageIndex = 1;
      this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.df-member-ship {
  height: 100%;

  .df-member-row {
    margin-top: 20px;

    .el-select,
    .el-input,
    .el-button {
      margin-left: 0;
      margin-right: 10px;
    }

    .el-select {
      width: auto;
      min-height: unset !important;
      max-width: unset;
    }

    .el-input {
      max-width: 200px !important;
    }
  }

  .grid-content {
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: #f9fafc;
    margin: 20px 0;

    span:first-child {
      margin-right: 10px;
    }

    span:last-child {
      color: #8492a6;
    }
  }

  .member-name {
    display: flex;
    align-items: center;

    .member-name-rigth {
      margin-right: 10px;

      .el-image {
        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .el-icon-female {
      color: tomato;
    }

    .el-icon-male {
      color: #409eff;
    }
  }

  .look-card {
    cursor: pointer;
  }

  .look-card:hover {
    color: #409eff;
  }

  .dime-sign {
    .flex-row;
    width: 100%;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    .sign-text {
      .flex-row;
      justify-content: center;
      height: 20px;
      width: 20px;
      margin: 0 3px;
      font-size: 14px;
      color: white;
      border-radius: 50%;
    }
  }

  .pagination {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .el-pagination {
      margin-top: 20px;
    }
  }
}
</style>
